import qs from "qs";
import { API } from "./host";

export const requestData = (action, data, method = "POST") => {
    return fetch(API + action, {
        method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: qs.stringify(data),
    })
        .then(async (response) => {
            if (!response.ok) {
                if (response.status === 400) {
                    var responseJson = await response.json();
                    return {
                        status: 400,
                        message: responseJson.message,
                        errors: responseJson.errors
                    }
                } else {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
            }
            return response.json();
        })
        .catch((error) => {
            if (error.status === 400) {
                return { success: false, message: error.message || 'Bad request' };
            } else {
                return { success: false, message: 'Request failed' };
            }
        });
};